<template>
  <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100 fail">
    <div class="primary--text text--darken-2 mb-14 link" @click="back">
      <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
    </div>
    <img class="d-block mx-auto" src="@/assets/icon/fail.svg" alt="fail" />
    <div class="f28 font-weight-bold mt-5 text-center">You used all available attempts to Sign In. Try again later</div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  methods: {
    back() {
      this.$router.push('/sign-in');
    },
  },
};
</script>
